@keyframes moveTruck {
    0% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(100%);
    }
}

@keyframes loadingDots {
    0% {
        content: '.';
    }

    33% {
        content: '..';
    }

    66% {
        content: '...';
    }

    100% {
        content: '.';
    }
}

.app-loading-container {
    height: calc(100vh - 74px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: #fff;
}

.app-loading {
    display: inline-flex;
    color: var(--color-primary);
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 2rem;
}

.app-loading .truck-icon {
    color: var(--color-primary);
    animation: moveTruck 2s alternate-reverse infinite;
    position: relative;
    left: -1.4rem;
    margin-right: 1rem;
}

.app-loading::after {
    content: '.';
    animation: loadingDots 1s steps(3) infinite;
    font-size: 1.3rem;
    font-weight: bold;
}
