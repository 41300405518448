@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

:root {
    --color-primary: #142851;
    --color-secondary: #ffa630;
    --color-dark: #505050;
    --color-input: #f0f0f0;
    --color-continue-building: #e6e6e6;
    --color-drivers: #f5f9ff;
    --color-ready: #fff199;
    --color-awaiting-confirmation: #ffecd2;
    --color-dispatched: #a4dfb2;
    --color-delivered: #ddb1ae;
    --color-readyForInvoice: #c5c6ff;
    --color-past-loads: #d8bf8a;
    --color-broker-card-background: #0b1221;
    --color-error: #d80000;
    --color-map-background: #c4c4c4;
    --color-disabled: #b7b4b1;
    --circle-size: clamp(0.75rem, 2.5vw, 1.5rem);
    --spacing: clamp(0.125rem, 1vw, 0.25rem);
}

body {
    margin: 0;
    font-family: 'Josefin Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
}

hr {
    color: lightgray;
    margin: 0px;
}

.routes-container {
    padding-top: 74px;
}

.container-padding {
    padding: 1.5% 5%;
}

.form-control.is-valid,
.form-check-label,
.form-control:valid {
    background-image: inherit !important;
    padding-right: 0 !important;
    color: initial !important;
}

.was-validated .form-select:valid {
    padding-right: 0 !important;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")
        no-repeat right 0.75rem center/8px 10px !important;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")
        no-repeat right 0.75rem center/8px 10px !important;
    background-color: var(--color-input) !important;
}

.input-background {
    background-color: var(--color-input);
    color: var(--color-primary);
    border-radius: 5px !important;
}

.small-button {
    font-size: 12px;
    border: 0;
}

.pointer {
    cursor: pointer;
}

.button {
    border-radius: 11px;
    font-size: 16px;
}

.button-border-radius {
    border-radius: 11px;
}

.transparent-button,
:not(.btn-check) + .transparent-button:active {
    background-color: transparent;
    font-weight: 600;
    border-radius: 11px;
    border: var(--color-primary) 2px solid;
    color: var(--color-primary);
}

.transparent-button:hover,
.transparent-button:active,
.btn:first-child:active {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--toastify-color-light);
}

.upload-file-button {
    background-color: white;
    border-width: 2px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-bottom: 20px;
}

.upload-file-button-success {
    color: var(--color-primary);
    border-color: var(--color-primary);
}

.upload-file-button-error {
    color: var(--color-error);
    border-color: var(--color-error);
}

.upload-file-button:hover {
    color: white;
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.file-name {
    padding-top: 2px;
    width: 150px;
    word-wrap: break-word;
    word-break: break-all;
}

.error {
    font-size: 14px;
    color: var(--color-error);
}

.cursor-pointer {
    cursor: pointer !important;
}

.color-primary {
    color: var(--color-primary) !important;
}

.phone-select {
    width: 65%;
}

.input-phone {
    width: 85%;
    padding-left: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.broker-input {
    margin: -10px;
}

.table-scroll-div {
    max-height: 582px;
    overflow-y: scroll;
    word-break: break-word;
    position: relative;
}

.table-scroll-div::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #cccccc;
    visibility: hidden;
}

.table-scroll-div::-webkit-scrollbar-thumb {
    background: #b9b9b9;
    border-radius: 10px;
}

.form-check-input[type='radio'] {
    border-color: var(--color-primary) !important;
}

.form-check-input[type='radio']:checked {
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
    width: 15px;
    height: 15px;
    background-size: 9px;
}

.shipment-history-table-scroll {
    max-height: 300px;
    overflow-y: scroll;
}

.table-list-view {
    position: sticky;
    top: 0;
    vertical-align: middle;
}

.background-primary-color {
    background-color: var(--color-primary);
    color: white;
}

.currency-input {
    height: 37px;
}

.currency-input.disabled {
    color: gray;
}

.react-daterange-picker__wrapper {
    border: 0 !important;
}

.react-daterange-picker__inputGroup__input {
    height: 40px !important;
    padding: 13px 11px 11px 16px;
}

.react-daterange-picker__inputGroup__input:invalid {
    background-color: transparent !important;
    outline: none;
}

.react-daterange-picker {
    width: 100%;
}

.react-daterange-picker__wrapper > .react-daterange-picker__inputGroup {
    display: flex;
    align-items: center;
}

.react-daterange-picker > .react-daterange-picker__wrapper {
    width: 100%;
    overflow: hidden;
}

.react-daterange-picker__inputGroup__input {
    font-size: 14px;
    padding: 0 3px;
}

.react-daterange-picker__button {
    margin-left: auto;
}

.react-daterange-picker__inputGroup {
    flex-grow: 0 !important;
}

label.color-primary.form-label {
    display: block;
    white-space: nowrap;
    width: calc(100% + 20px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.attachment-table-scroll {
    padding: 0px !important;
    overflow-y: scroll;
}

.attachment-table .attachment-data {
    vertical-align: middle;
    margin-bottom: none !important;
}

.commodity-table tr.commodity-row.even,
.attachment-table tr.attachment-row.even {
    background-color: #f0f0f0;
}

.mb-3 {
    margin-bottom: 1.5rem !important;
}

.state-field-width {
    width: 43%;
}

.zipcode-field-width {
    width: 57%;
}

.customer-notes-height textarea {
    height: 38px;
    resize: none;
    line-height: 26px;
}

.disabled {
    color: #14285199 !important;
}

.shipmentrecord-order-wrapper textarea,
.shipmentrecord-driver-wrapper textarea {
    height: 124px;
    resize: none;
}

.time-of-delete-icon {
    margin-left: 13px;
    margin-top: 40px;
}

.drivers-details .time-of-delete-icon {
    margin-top: 35px;
}

.profile-img-container {
    position: relative;
    height: 8rem;
    width: 8rem;
    overflow: hidden;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.drivers-top-details .profile-img-container {
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
}

.profile-image {
    border: none;
    width: 100%;
    height: 100%;
    padding: 0px;
}

.profile-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 8rem;
    width: 8rem;
    opacity: 0;
    font-size: 1.5rem;
    transition: 0.3s ease;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-img-container:hover .profile-overlay {
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1;
    color: white;
    font-size: 1rem;
}

.warning-text {
    color: #ff5858;
    font-style: italic;
}

.shipment-history-table-scroll thead th {
    white-space: nowrap;
}

.shipment-history-table-scroll thead th,
.shipment-history-table-scroll tbody td {
    padding: 8px !important;
    min-width: 100px;
}

.submit-button {
    background-color: var(--color-secondary);
    color: var(--color-primary);
}

.submit-button:hover {
    background-color: var(--color-secondary) !important;
    color: var(--color-primary) !important;
}

@media (max-width: 1199px) {
    .container-padding {
        padding: 20px 20px;
    }
}

@media (max-width: 767px) {
    label.color-primary.form-label {
        width: 100%;
    }

    .table-scroll-div {
        max-height: calc(100vh - 200px);
        margin-top: 10px !important;
    }

    .shipment-history-table-scroll {
        max-height: 450px;
        border: 1px solid #f4f4f4;
    }

    .shipment-history-table-scroll .table {
        margin-bottom: 0px;
    }

    .shipment-history-table-scroll thead {
        display: none;
    }

    .shipment-history-table-scroll tbody td {
        display: block;
        padding: 7px 10px 7px 140px !important;
        position: relative;
        font-size: 15px;
        line-height: 20px;
    }

    .commodity-table tr.commodity-row,
    .attachment-table tr.attachment-row {
        background: #fff;
    }

    .shipment-history-table-scroll tbody tr {
        border-bottom: 1px solid rgba(255, 255, 255, 1);
    }

    .shipment-history-table-scroll tbody td:empty {
        display: none;
    }

    .shipment-history-table-scroll tbody td:before {
        background: var(--color-primary);
        color: #fff;
        position: absolute;
        left: 0px;
        top: 0px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 7px;
        min-width: 130px;
        content: attr(data-name);
    }
}

@media (max-width: 359px) {
    .container-padding {
        padding: 20px 15px;
    }
}
