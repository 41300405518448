::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: #fcfcfc;
}

::-webkit-scrollbar-thumb {
    background: #dedede;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--color-primary);
    font-weight: 700;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    opacity: var(--bs-btn-disabled-opacity);
    color: var(--bs-btn-color);
}

.primary-button,
.primary-button:disabled {
    background-color: var(--color-secondary);
    color: var(--color-primary);
    border-radius: 10px;
    padding: 8px 15px;
    font-weight: 500;
    border: none;
    text-decoration: none;
}

.primary-button:hover,
.primary-button:active {
    background-color: var(--color-primary) !important;
    color: #fff !important;
}

.secondary-button {
    background-color: var(--color-primary);
    color: #fff;
    border-radius: 10px;
    padding: 8px 15px;
    margin-right: 10px;
    font-weight: 500;
    border: none;
    text-decoration: none;
}

.secondary-button:hover,
.secondary-button:active {
    background-color: var(--color-secondary) !important;
    color: var(--color-primary) !important;
}

.text-underline-button {
    background: none;
    border: none;
    color: var(--color-primary);
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap;
    padding: 0.375rem 0px;
}

.text-underline-button:hover {
    color: var(--color-primary);
    background-color: var(--white);
    text-decoration: none;
}

.form-check-input:checked {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.form-check-input:focus {
    box-shadow: none;
    border-color: var(--color-primary);
}

.modal-header {
    border: none;
}

.modal-title {
    size: 36px;
    color: var(--color-primary);
    font-weight: 700;
}

.modal-footer {
    border: none;
}

.modal-body {
    font-weight: 400;
    color: var(--color-primary);
}

.form-control,
.form-control:disabled,
.form-control:focus {
    background-color: var(--color-input);
    font-weight: 400;
}

.form-control:disabled {
    opacity: 0.75;
}

.error-message-fields {
    font-size: 12px;
}

.google-map-container {
    height: 100%;
    width: 100%;
    border-radius: 7px;
}

.disabled-button,
.disabled-button:disabled {
    background-color: var(--color-disabled);
    color: var(--color-primary);
    border-radius: 10px;
    padding: 8px 15px;
    font-weight: 500;
    border: none;
}

.clickable:hover {
    cursor: pointer;
}

.arrow-cursor:hover {
    cursor: default;
}

.contact-delete-icon {
    padding-left: 0px;
}

.contact-delete-icon svg {
    margin-top: 0px !important;
}

.document-delete-icon {
    margin-top: 32px;
    padding-bottom: 0px !important;
}

.document-items .form-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.with-remove-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.with-remove-icon > div.mb-3 {
    width: calc(100% - 21px);
    padding-right: 15px;
}

.with-remove-icon .contact-delete-icon {
    width: 21px;
}

.form-label {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0.5rem;
    display: block;
}

.document-select-outer .custom-select,
.document-items .custom-select {
    min-width: 165px;
    max-width: 165px;
}

.document-items .col-md-6,
.document-items .col-md-5 {
    width: auto;
}

.document-items svg.form-label {
    width: 21px;
    height: 28px;
}

.mobile-view-element {
    display: none;
}

.driver-info-modal .mb-3 {
    margin-bottom: 15px !important;
}

.rowmargin-remove {
    margin: 0px;
    padding: 0px;
}

.form-item.input-background {
    min-height: 38px !important;
}

.document-fields .file-name {
    word-break: break-all;
}

.document-fields .document-icon {
    margin-right: 10px;
}

.document-select-outer {
    margin-left: 15px;
}

.shipment-customers-detail hr {
    margin: 20px auto !important;
}

.shipment-customers-detail .broker-detail-head {
    padding-bottom: 0px !important;
}

.shipment-customers-detail .secondary-button {
    margin: 0px !important;
}

.customer-broker-detail .broker-documents {
    margin-top: 30px;
}

.broker-pin-drops {
    margin-top: 30px;
}

.add-new-driver-modal .document-items {
    padding: 0px !important;
    flex-wrap: wrap;
}

.add-new-driver-modal .profile-img-container {
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
}

.upload-another-file-button {
    width: 100%;
}

.add-new-driver-modal .document-delete-icon {
    margin-top: 25px;
}

.offcanvas-backdrop.show {
    display: none;
}

.drivers-details .upcoming-time-off-wrapper .form-item.input-background {
    max-width: 225px;
    min-width: 225px;
}

.driver-details-documents .document-icon {
    margin-right: 10px;
}

.driver-details-documents .document-delete-icon {
    margin-top: 25px;
}

.drivers-top-details > .col-lg-2 {
    width: 180px;
    padding-right: 0px;
}

.drivers-top-details > .col-lg-10 {
    width: calc(100% - 180px);
}
.drivers-top-details textarea {
    min-height: 125px;
}

.offcanvas.offcanvas-start {
    width: 260px;
    background: var(--color-primary);
    top: 74px;
    height: calc(100% - 74px);
    right: auto;
    left: 0px;
}
.offcanvas-end.offcanvas.offcanvas-start {
    right: 0px;
    left: auto;
}

@media (max-width: 991px) {
    .modal-title,
    .modal-body h4 {
        font-size: 20px;
    }

    .modal-body label {
        font-size: 14px;
    }

    .modal-body label.color-primary {
        font-size: 16px;
    }

    .modal-body .mb-3 {
        margin-bottom: 15px !important;
    }

    .state-field-width,
    .phone-select {
        width: 100px !important;
    }

    .zipcode-field-width,
    .input-phone {
        width: calc(100% - 100px) !important;
    }

    .zipcode-field-width,
    .state-field-width {
        width: 50% !important;
    }

    .state-zipcode-fieldouter {
        gap: 10px !important;
    }

    .modal-body .file-name {
        width: 100% !important;
    }

    .with-remove-icon > div.mb-3 {
        width: 100%;
        padding-right: 0px;
    }

    .with-remove-icon .contact-delete-icon {
        display: none;
    }

    .location-modal .modal-title,
    .driver-info-modal .modal-title {
        padding-left: 10px !important;
        padding-top: 10px !important;
    }

    .driver-info-modal .cargo-blocks {
        margin-top: 30px;
    }

    .location-modal .modal-body,
    .driver-info-modal .modal-body {
        padding: 0px;
    }

    .location-modal-map-container .map-instruction {
        width: 100% !important;
    }

    .location-modal-map-container {
        margin-bottom: 20px;
        width: 100% !important;
        height: 460px !important;
    }

    .address-field {
        margin-bottom: 25px;
    }

    .location-modal .modal-body hr.my-4 {
        background: none;
        border: none;
        margin: 12px auto !important;
    }

    .location-modal .modal-footer {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .location-modal-map-container .map-instruction {
        line-height: normal;
        padding: 7px !important;
    }

    .driver-locations-container {
        grid-template-columns: repeat(3, 33.33%) !important;
        grid-gap: 0px !important;
    }

    .pin-drops {
        margin: 15px -10px !important;
    }

    .customer-broker-detail hr {
        margin: 25px auto !important;
        padding: 0px !important;
    }

    .customer-broker-detail hr:first-of-type {
        margin: 10px auto !important;
    }

    .broker-pin-drops {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .board-list-child-container,
    .right-filter-btn {
        display: none !important;
    }

    .right-filter-btn {
        position: fixed;
        right: 15px;
        z-index: 10;
        top: 74px;
        width: 40px;
        height: 40px;
        display: block !important;
        padding: 8px;
        background: var(--color-primary) !important;
        border: none;
        border-radius: 50px 0px 0px 50px;
        -webkit-border-radius: 50px 0px 0px 50px;
        -moz-border-radius: 50px 0px 0px 50px;
        -ms-border-radius: 50px 0px 0px 50px;
        -o-border-radius: 50px 0px 0px 50px;
    }

    .drivers-wrap::before {
        content: '';
        background: var(--color-primary);
        width: 15px;
        height: 100%;
        position: fixed;
        right: 0px;
        top: 0px;
    }

    label.color-primary.form-label {
        width: 100% !important;
    }

    .drivers-wrap {
        padding-right: 15px;
    }

    .offcanvas-header {
        padding: 10px 20px;
    }

    .offcanvas-header .btn-close {
        background: var(--color-primary);
        position: absolute;
        right: -30px;
        top: 8px;
        opacity: 1;
        width: 40px;
        height: 40px;
        padding: 0px;
        border-radius: 0px 50px 50px 0px;
    }

    .offcanvas-header .btn-close::before {
        content: '';
        width: 3px;
        height: 24px;
        background: var(--toastify-color-light);
        position: absolute;
        left: 15px;
        border-radius: 5px;
        top: 7px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }

    .offcanvas-header .btn-close::after {
        content: '';
        width: 3px;
        height: 24px;
        background: var(--toastify-color-light);
        position: absolute;
        left: 15px;
        top: 7px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        border-radius: 5px;
    }

    .offcanvas-title {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
    }

    .offcanvas.offcanvas-end {
        left: auto;
    }

    .offcanvas-end .offcanvas-header .btn-close {
        right: auto;
        left: -40px;
        border-radius: 50px 0px 0px 50px;
        -webkit-border-radius: 50px 0px 0px 50px;
        -moz-border-radius: 50px 0px 0px 50px;
        -ms-border-radius: 50px 0px 0px 50px;
        -o-border-radius: 50px 0px 0px 50px;
    }

    .offcanvas-end .offcanvas-header .btn-close::before,
    .offcanvas-end .offcanvas-header .btn-close::after {
        left: 22px;
    }

    .warning-documents-tooltip {
        position: relative;
        z-index: 2;
    }

    .warning-documents-tooltip:before {
        content: attr(data-bs-toggle);
        position: absolute;
        font-size: 10px;
        right: calc(100% + 10px);
        bottom: 0px;
        min-width: 180px;
        background: var(--color-broker-card-background);
        color: var(--toastify-color-light);
        padding: 5px;
        line-height: 12px;
        border-radius: 4px;
        opacity: 0;
        visibility: hidden;
    }

    .warning-documents-tooltip:after {
        content: '';
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 7px solid var(--color-broker-card-background);
        position: absolute;
        right: calc(100% + 4px);
        bottom: 5px;
        opacity: 0;
        visibility: hidden;
    }

    .warning-documents-tooltip:hover::before,
    .warning-documents-tooltip:hover::after {
        visibility: visible;
        opacity: 1;
    }
}

@media (max-width: 767px) {
    .p-4.modal-body {
        padding: 20px !important;
    }

    .mt-3.px-1.modal-body {
        padding: 10px 0px !important;
        margin: 0px !important;
        font-size: 16px;
        line-height: 20px;
    }

    .shipment-success-modal .modal-title {
        padding-bottom: 10px !important;
    }

    .shipment-success-modal .secondary-button {
        min-width: 206px;
        margin-right: 0;
    }

    .shipment-success-modal .btn {
        min-width: 205px;
    }

    .shipment-success-modal .d-flex {
        flex-wrap: wrap;
        justify-content: center !important;
    }

    .document-select-outer .custom-select,
    .document-items .custom-select {
        max-width: 100%;
    }

    .document-items .col-md-6 {
        width: 100%;
    }

    .document-items .col-md-5 {
        width: 91.66666667%;
    }

    .shipmentFooter-btns .btn {
        margin-bottom: 10px;
    }

    .priority-invoice-outer {
        justify-content: space-between;
    }

    .priority-invoice-outer .col-6 {
        width: auto;
        min-width: 150px;
    }

    .anothor-bill-lading-modal .modal-title,
    .attach-bill-modal .modal-title {
        padding-left: 10px !important;
        padding-top: 10px !important;
    }

    .anothor-bill-lading-modal .modal-body,
    .attach-bill-modal .modal-body {
        margin: 0px 10px !important;
    }

    .anothor-bill-lading-modal .modal-footer,
    .attach-bill-modal .modal-footer {
        margin: 0px 10px !important;
        padding: var(--bs-modal-padding);
    }

    .anothor-bill-lading-modal .modal-footer .secondary-button {
        margin: 0px;
    }

    .attach-bill-modal .upload-file-button {
        margin-bottom: 0px;
        width: 100%;
    }

    .attach-bill-modal .dropzone {
        height: 100px;
    }

    .attach-bill-modal .modal-body .pt-5 {
        padding-top: 40px !important;
    }

    .attach-bill-modal .modal-header {
        padding-bottom: 0px;
    }

    .setting-page h3 {
        font-size: 22px;
    }

    .setting-page h5 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .table-responsive {
        margin-bottom: 10px;
    }

    .table-responsive table {
        margin-bottom: 0px;
    }

    .table-responsive thead th {
        font-size: 14px;
        white-space: nowrap;
        min-width: 100px;
        padding: 7px 5px;
    }

    .table-responsive tbody td {
        font-size: 14px;
        line-height: 18px;
        min-width: 100px;
        padding: 7px 5px !important;
    }

    .customer-name-field {
        margin-top: 20px;
    }

    .customers-top-filters {
        margin-top: 0px !important;
    }

    .broker-detail-head .transparent-button {
        margin-left: 0px !important;
    }

    .driver-locations-container {
        grid-template-columns: repeat(2, 50%) !important;
    }

    .driver-locations-container .col-md-1 {
        width: auto;
        padding-right: 0px;
    }

    .broker-documents .d-flex .d-flex {
        flex-wrap: wrap;
        width: 100%;
    }

    .broker-documents .document-fields {
        width: 100%;
    }

    .document-select-outer {
        margin-left: 0px;
        margin-top: 15px;
    }

    .broker-documents .file-name {
        width: 100%;
        flex-wrap: nowrap !important;
    }

    .broker-documents .ms-5.d-flex {
        margin-left: 1rem !important;
        width: 100%;
    }

    .broker-documents .document-icon {
        padding-top: 0px !important;
    }

    .broker-documents .d-flex.gap-3 {
        width: 100%;
    }

    .broker-documents .d-flex.gap-3 .field-box {
        width: calc(50% - (0.5rem + 1rem + 21px));
    }

    .driver-details-documents .file-name {
        width: 100%;
    }

    .drivers-top-details > .col-lg-2 {
        width: 100%;
        padding-right: 15px;
        margin-bottom: 20px;
    }

    .drivers-top-details > .col-lg-10 {
        width: 100%;
    }
    .drivers-top-details textarea {
        min-height: 80px;
    }
}

@media (max-width: 576px) {
    .document-delete-col {
        padding-left: 0px;
        padding-right: 0px;
    }

    .contact-delete-icon {
        padding-right: 0px;
    }

    .contact-delete-icon svg {
        margin-top: 35px !important;
    }

    .shipmentFooter-btns .btn {
        font-size: 14px;
        padding: 8px 10px;
        min-height: 42px;
        width: calc(100% / 2 - 5px);
    }

    .shipmentFooter-btns.col-lg-6 {
        justify-content: space-between !important;
        display: flex;
        flex-wrap: wrap;
    }

    .shipmentFooter-btns .btn {
        margin-bottom: 0px;
    }

    .desktop-view-element {
        display: none;
    }

    .mobile-view-element {
        display: block;
    }

    .secondary-button.mobile-view-element.btn {
        display: inline-block;
        margin-left: 10px;
        margin-right: 0px;
    }

    .footer-btns-rowone .btn {
        width: calc(50% - 5px);
        font-size: 15px;
        padding: 7px 5px;
        min-height: 42px;
    }

    .footer-btns .btn {
        width: 100%;
    }

    .shipment-detail-footer-rowone .mt-2 {
        width: 100%;
        margin-top: 0px !important;
        margin-bottom: 10px;
    }

    .shipment-detail-footer-rowone .btn {
        width: 100%;
        margin-right: 0px !important;
    }

    .buttons-rowone {
        display: flex;
        flex-wrap: wrap;
    }

    .buttons-rowone .transparent-button {
        margin-top: 10px;
    }

    .buttons-rowone .primary-button {
        margin-top: 10px;
    }

    .attach-bill-modal .modal-body .pt-5 {
        padding-top: 10px !important;
    }

    .broker-fee-shipment-modal .col-lg-6 {
        width: 50%;
    }

    .driver-info-modal .cargo-blocks {
        margin-bottom: 15px;
    }

    .charges-button {
        margin-right: auto;
        width: 100%;
    }

    .upload-file-button-success {
        margin-top: 10px !important;
        margin-bottom: 0px !important;
    }

    .modal-body label.color-primary {
        font-size: 14px;
    }

    .customer-apply-button .driver-apply-button .secondary-button {
        width: 100%;
        margin-right: auto;
    }

    .customer-broker-detail .clear-filter-button,
    .shipment-customers-detail .clear-filter-button,
    .drivers-details .clear-filter-button {
        justify-content: flex-start;
    }

    .broker-documents .d-flex.gap-3 .field-box {
        width: 100%;
    }

    .broker-documents .d-flex.gap-3 .field-box:first-of-type {
        width: calc(100% - (21px + 1rem));
    }

    .broker-documents .d-flex.gap-3 {
        position: relative;
    }

    .document-fields svg.fa-trash-can {
        position: absolute;
        right: 0px;
        margin-top: 20px !important;
    }

    .broker-back-btn {
        margin-right: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .driverlist-modal-buttons .btn {
        width: 100%;
    }

    .driverlist-modal-buttons .secondary-button {
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .drivers-top-details .col-12 {
        margin-bottom: 1.5rem !important;
    }

    .drivers-top-details .col-12.mb-0 {
        margin-bottom: 0px !important;
    }

    .drivers-top-details .col-12 .mb-3,
    .drivers-top-details .col-12.mb-sm-4,
    .drivers-top-details > .col-sm-9.col-12,
    .drivers-top-details > .col-sm-9.col-12 > .row .col-lg-3.col-md-12 {
        margin-bottom: 0 !important;
    }

    .broker-documents .document-fields.mb-3 {
        margin-bottom: 0px !important;
    }

    .customer-broker-detail .broker-documents .document-fields.mb-3 {
        margin-bottom: 1.5rem !important;
    }

    .drivers-details .secondary-button {
        margin: 0px !important;
    }

    .broker-detail-head .btn {
        margin-bottom: 15px;
    }

    .broker-detail-head {
        padding-bottom: 0px !important;
    }

    .add-new-driver-modal .modal-body h4 {
        padding: 0px;
    }

    .add-new-driver-modal .modal-body {
        padding: 0px;
    }

    .add-new-driver-modal .modal-header {
        padding-left: 0px;
        padding-right: 0px;
    }

    .add-new-driver-modal .modal-header h2 {
        margin-bottom: 0px;
    }

    .drivers-details .upcoming-time-off-wrapper .form-item.input-background {
        max-width: 100%;
        min-width: 0;
        width: 100%;
    }

    .drivers-details .upcoming-time-off-wrapper .mb-3 {
        width: calc(100% - 34px);
    }
}

@media (max-width: 359px) {
    .priority-invoice-outer .col-6 {
        min-width: auto;
    }

    .location-modal .modal-body,
    .location-modal .modal-footer {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    .location-modal .modal-title {
        padding-left: 0px !important;
    }

    .driver-locations-container {
        grid-template-columns: repeat(1, 100%) !important;
    }
}

@media (min-width: 992px) {
    .add-cargo-line-row .col-lg-9 {
        width: calc(100% - 18.75%);
    }

    .add-cargo-line-row .col-lg-2 {
        width: 10.42%;
    }

    .drivers-top-details .col-lg-10 > .row .col-12 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .drivers-top-details .col-lg-10 > .row > .col-lg-3:first-of-type,
    .drivers-top-details .col-lg-10 > .row .col-lg-9:first-of-type {
        padding-left: 15px;
    }

    .drivers-top-details .col-lg-10 > .row > .col-lg-3:last-of-type,
    .drivers-top-details
        .col-lg-10
        > .row
        .col-lg-9
        .row
        > .col-lg-4:last-of-type {
        padding-right: 15px;
    }

    .drivers-top-details .col-lg-10 > .row .d-flex.gap-1 {
        gap: 16px !important;
    }

    .drivers-top-details .input-phone,
    .drivers-top-details .phone-select {
        width: 50%;
    }
}

.highlighted-container {
    background: var(--color-primary);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.underlined-text {
    text-decoration: underline;
}
