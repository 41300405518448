.navbar {
    background-color: var(--color-primary);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    padding: 8px 20px;
    display: flex;
    justify-content: space-between;
}

.navbar-option {
    color: white;
    padding-left: 35px !important;
}

.navbar-option-selected {
    color: var(--color-secondary) !important;
    text-decoration-line: underline;
    text-decoration-thickness: 3px;
    padding-left: 35px !important;
}

.profile-dropdown-title {
    background-color: var(--color-primary) !important;
    border: none;
    padding: 12px 0px;
    font-size: 18px;
}

.profile-dropdown-title .dropdown-toggle::after {
    vertical-align: 2px;
}

.profile-dropdown-title:hover {
    color: var(--color-secondary);
    border-color: var(--color-secondary);
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: static;
    margin-top: 0px;
}

.dropdown-menu {
    left: -130px !important;
    font-size: 15px;
}

.dropdown-menu .dropdown-item:active {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg);
}

.profile-dropdown-menu {
    left: -10px !important;
    font-size: 15px;
}

.user-profile-toggler {
    color: white;
    margin: -6px 6px 0px 10px;
    border: 2px solid;
    background: none;
    border-radius: 50%;
    width: 26px;
    height: 25px;
    overflow: hidden;
    padding: 0px 4px;
    transition: 0.3s;
}

.user-profile-toggler:hover {
    background-color: var(--color-primary);
    color: var(--color-secondary);
    border-color: var(--color-secondary);
}

.user-profile-toggler svg {
    margin-bottom: -3.5px;
}

.localization-dropdown-toggle {
    margin: -0.5px 6px 0px 10px;
}

.settings-select-icon {
    color: white;
    margin: -0.5px 6px 0px 10px;
}

.settings-select-icon-active {
    color: var(--color-secondary);
}
.support-icon {
    color: #ffffff;
    margin-top: 1px;
    padding: 0px 4px;
}
.support-icon-active {
    color: var(--color-secondary);
}

.nav-inside {
    display: flex;
}

.dropdown .btn {
    background: none !important;
}

.dropdown .btn.user-profile-toggler,
.dropdown .btn.user-profile-toggler:active {
    border-color: var(--bs-btn-active-color);
}

.dropdown .user-profile-toggler:hover {
    border-color: var(--color-secondary);
}

@media (max-width: 991px) {
    .navbar {
        padding: 17px 20px;
    }

    .navbar-brand {
        margin-right: 0px;
    }

    .navbar-brand img {
        max-width: 77px;
        height: auto;
    }

    .navbar-text {
        color: #fff;
        font-size: 20px;
    }

    .navbar-expand .navbar-option {
        padding: 5px 15px !important;
    }

    .navbar-expand .navbar-toggler {
        display: block;
        outline: none;
        box-shadow: none;
        border: none;
        padding: 0px;
        width: 24px;
        height: 24px;
        position: relative;
        margin-left: 10px;
    }

    .navbar-toggler-icon {
        width: 24px;
        height: 3px;
        background: #fff;
        position: absolute;
        display: block;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    }

    .navbar-toggler-icon:before {
        width: 24px;
        height: 3px;
        content: '';
        background: #fff;
        position: absolute;
        top: -9px;
        left: 0px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }

    .navbar-toggler-icon:after {
        width: 24px;
        height: 3px;
        content: '';
        background: #fff;
        position: absolute;
        bottom: -9px;
        left: 0px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }

    .navbar-expand .navbar-collapse {
        position: fixed;
        display: block !important;
        width: 210px;
        right: -210px;
        top: 0px;
        height: 100%;
        background: var(--color-primary);
        z-index: 5;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    }

    .navbar-expand .navbar-collapse.show {
        right: 0px;
    }

    .show .navbar-toggler .navbar-toggler-icon {
        background: none;
    }

    .show .navbar-toggler .navbar-toggler-icon:before {
        top: 0px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
    }

    .show .navbar-toggler .navbar-toggler-icon:after {
        top: 0px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
    }

    .navbar-expand .navbar-nav {
        flex-direction: column;
        display: block;
    }

    .nav-inside {
        display: block;
        height: calc(100vh - 70px);
        overflow: auto;
        padding: 0px 20px 25px;
        margin-top: 70px;
    }

    .navbar-expand .navbar-collapse .navbar-toggler {
        position: absolute;
        left: 20px;
        top: 25px;
        margin-left: 0px;
    }

    .navbar-expand .navbar-option,
    .navbar-option-selected {
        padding: 10px 0px !important;
        font-size: 18px;
    }

    .navbar-option-selected {
        text-decoration: none;
    }

    .navbar-option-selected span {
        border-bottom: 1px solid;
    }
}

@media (max-width: 359px) {
    .navbar {
        padding: 17px 15px;
    }

    .navbar-text {
        font-size: 16px;
    }
}
